import { graphql, StaticQuery } from "gatsby";
import React from "react";
import "./pricing.scss";
import {
  getPage,
  TypePricingPage,
  TypePricingQueryResult,
} from "../../utils/queries/pricing";
import {
  PageHeaderCustomV2WhiteWide,
  PageHeaderStandard,
} from "../../components/page-header/page-header";
import Helmet from "react-helmet";
import { PageTitle } from "../../components/title/title";
import AmazeeIoMetadata from "../../components/metadata";
import RichTextRender from "../../utils/rich-text";
import AmazeePage from "../../components/layout";
import linkResolver from "../../utils/linkResolver";
import usePreviewData from "../../utils/usePreviewData";
import { ResourceInteruption } from "../../templates/resources/customers";
import { QuoteCarousel } from "../../components/reusable/quote-carousel";
import { TypeHomepageV2Quote } from "../../utils/queries/homepagev2";
import {
  CustomerStandardCard,
  StandardCard,
} from "../../templates/resources/cards";
import { Resource } from "../../utils/queries/resources";

const query = graphql`
  {
    allPrismicPricing {
      edges {
        node {
          data {
            page_meta_description
            title
            meta_title
            hero_image {
              alt
              url
            }
            subtitle {
              html
              raw
              text
            }
            cloud_section_heading
            cloud_packages {
              cloud_package_heading
              cloud_subheading
              cloud_package_image {
                url
                alt
              }
              pricing_starting_at
              pricing_additional_projects
              guaranteed_uptime
            }
            cloud_package_extra_text {
              html
              text
              raw
            }
            cloud_included {
              html
              text
              raw
            }
            cloud_basic_included {
              html
              text
              raw
            }
            cloud_available_add_ons {
              cloud_add_on_heading
              add_ons_copy {
                html
                text
                raw
              }
              cloud_contact_sales {
                url
              }
            }
            support_packages_footer_text {
              html
              raw
              text
            }
            dedicated_cloud_section_heading
            ded_cloud_package_image {
              url
              alt
            }
            ded_cloud_subheading
            ded_custom_pricing_text {
              html
              text
              raw
            }
            ded_cloud_package_extra_text {
              html
              text
              raw
            }
            dedicated_cloud_features {
              html
              text
              raw
            }
            dedicated_cloud_guaranteed_uptime {
              html
              text
              raw
            }
            dedicated_cloud_included {
              html
              text
              raw
            }
            dedicated_cloud_add_ons {
              html
              text
              raw
            }
            dedicated_cloud_contact_sales {
              url
            }
            faqs_section_heading
            faq_items {
              faq_copy {
                html
                text
                raw
              }
              faq_heading
            }
            support_packages_heading
            packages_grid {
              package_heading
              grt_heading
              critical_urgency
              high_urgency
              low_urgency
              package_price {
                html
                text
                raw
              }
            }
            upscale_your_team_and_plans_heading
            upscale_grid {
              upscale_icon {
                alt
                url
              }
              upscale_heading
              upscale_copy
            }
            page_meta_thumbnail {
              url
              alt
            }
            aws_image {
              alt
              url
            }
            aws_heading
            aws_sub_heading
            feature_grid {
              feature_copy
            }
            aws_cta_link {
              url
            }
            cta_title
            cta_link {
              url
            }
          }
        }
      }
    }
  }
`;

const PricingQueryPage = ({
  data,
  location,
}: {
  location: Location;
  data: TypePricingQueryResult;
}) => {
  const pageData = getPage(data);
  if (!pageData) return null;
  const result = usePreviewData(
    pageData,
    "PrismicPricing",
    true
  ) as TypePricingPage;

  const title = result.meta_title ? result.meta_title : result.title;

  return (
    <AmazeePage location={location}>
      <div id="pricing">
        <Helmet>
          <link rel="stylesheet" href="/css/carousel-single.min.css" />
          <link rel="stylesheet" href="/css/carousel-multi.min.css" />
        </Helmet>
        <PageTitle title={title} amazeeOnly={true} />
        <AmazeeIoMetadata
          title={result.title}
          page_meta_description={result.page_meta_description}
          page_meta_thumbnail={result.page_meta_thumbnail}
          location={location}
        />

        <PageHeaderStandard
          component={PageHeaderCustomV2WhiteWide}
          header={result.title}
          image={result.hero_image}
          subheader={result.subtitle}
          withSiteName={false}
          hasSectionDivider={false}
        />

        <section id="packages-projects">
          <div className="container block py-16">
            <div className="row flex justify-center">
              <div className="col-12 col-lg-5 text-center">
                <h2 className="block mt-0 mb-16 packages-title">
                  Simple, transparent pricing plans. Only pay for what you use.
                </h2>
              </div>
            </div>

            <div className="inner-packages lg:grid gap-5 grid-cols-1 col-lg-10 mx-auto px-0">
              <div className="cloud-package col-span-3 packge-column">
                {/* <h3 className="text-5xl py-8 font-bold text-center cloud-heading w-full">{result.cloud_section_heading}</h3> */}
                {result.cloud_packages.map((cloud_package, index) => (
                  <div
                    data-item={index}
                    className="cloud-tier w-full lg:w-2/4 mb-4 lg:mb-0 col-item flex flex-col text-start justify-start items-start relative"
                  >
                    <div className="text-4xl font-bold w-full service-title mb-4">
                      <div className="package-img">
                        <img
                          src={cloud_package.cloud_package_image.url}
                          alt={cloud_package.cloud_package_image.alt}
                        />
                      </div>
                      {cloud_package.cloud_package_heading}
                    </div>
                    <div className="subheading">
                      {cloud_package.cloud_subheading}
                    </div>
                    <div className="uptime">
                      <span className="font-semibold mr-2">
                        {cloud_package.guaranteed_uptime}
                      </span>
                      <span>Guaranteed Uptime</span>
                    </div>
                    <div className="description price-starting-at w-full mb-4">
                      <div className="w-full p-0 font-semibold starting-at">
                        Starting at
                      </div>
                      <span className="text-5xl font-bold price-field">
                        {cloud_package.pricing_starting_at}
                      </span>
                      <span className="per-month"> per month</span>
                    </div>
                    <div className="description">
                      <div className="w-full p-0">Every additional project</div>
                      <span className="text-4xl additonal-proj-price font-bold">
                        {cloud_package.pricing_additional_projects}
                      </span>
                      <span className="per-month"> per month</span>
                    </div>
                    <div className="ded-contact lg:hidden block">
                      <a href="#">Contact Sales</a>
                    </div>

                    <div className="package-included mobile-only hidden lg:grid">
                      <div className="comes-with-one inline-block">
                        <h3 className="small">Comes with:</h3>
                        <div className="package-copy flex flex-col justify-start items-start">
                          {RichTextRender(result.cloud_basic_included)}
                        </div>
                      </div>
                      <div className="comes-with-two lg:block hidden">
                        <h3 className="small">Comes with:</h3>
                        <div className="package-copy flex flex-col justify-start items-start">
                          {RichTextRender(result.cloud_included)}
                        </div>
                      </div>
                    </div>
                    <div className="Package-footer-text  mobile-only">
                      {RichTextRender(result.cloud_package_extra_text)}
                    </div>
                  </div>
                ))}

                <div className="add-ons lg:grid hidden">
                  {result.cloud_available_add_ons.map(
                    (cloud_available_add_on, index) => (
                      <div
                        data-item={index}
                        className="add-on w-full lg:mb-0 col-item flex flex-col justify-start items-center relative"
                      >
                        <div className="ded-contact">
                          <a
                            href={
                              cloud_available_add_on.cloud_contact_sales.url
                            }
                          >
                            Contact Sales
                          </a>
                        </div>
                      </div>
                    )
                  )}
                </div>

                <div className="package-included lg:grid">
                  <div className="comes-with-one hidden lg:inline-block">
                    <h3 className="small">Comes with:</h3>
                    <div className="package-copy flex flex-col justify-start items-start">
                      {RichTextRender(result.cloud_basic_included)}
                    </div>
                  </div>
                  <div className="comes-with-two lg:block hidden">
                    <h3 className="small">Comes with:</h3>
                    <div className="package-copy flex flex-col justify-start items-start">
                      {RichTextRender(result.cloud_included)}
                    </div>
                  </div>
                </div>
                <div className="Package-footer-text desktop-only">
                  {RichTextRender(result.cloud_package_extra_text)}
                </div>
                <div className="Package-footer-line"></div>
              </div>

              <div className="dedicated-cloud-package col-span-2 packge-column">
                <div className="text-4xl font-bold w-full service-title mb-4">
                  <div className="package-img">
                    <img
                      src={result.ded_cloud_package_image.url}
                      alt={result.ded_cloud_package_image.alt}
                    />
                  </div>
                  {result.dedicated_cloud_section_heading}
                </div>
                <div className="subheading">{result.ded_cloud_subheading}</div>

                <div className="ded-uptime">
                  <span className="font-semibold mr-2">
                    {RichTextRender(result.dedicated_cloud_guaranteed_uptime)}
                  </span>
                  <span>Guaranteed Uptime</span>
                </div>
                <div className="ded-custom-pricing">
                  <div className="font-semibold heading">
                    Custom pricing.
                  </div>
                  <div className="custom-text">
                    {RichTextRender(result.ded_custom_pricing_text)}
                  </div>
                </div>

                <div className="ded-contact">
                  <a href={result.dedicated_cloud_contact_sales.url}>
                    Contact Sales
                  </a>
                </div>

                <div className="ded-included flex flex-col justify-start items-start">
                  <h3 className="small">Comes with:</h3>
                  {RichTextRender(result.dedicated_cloud_included)}
                </div>

                <div className="Package-footer-text">
                  {RichTextRender(result.ded_cloud_package_extra_text)}
                </div>
              </div>
            </div>
            <p className="block packages-sub-sub mt-8 text-center mb-0 text-base">
              All prices in US Dollars.
            </p>
          </div>
        </section>

        <section id="upscale-your-team-and-plans">
          <div className="container block py-16">
            <div className="row flex justify-center">
              <div className="col-12 col-lg-10 text-center">
                <h2 className="block mt-0 mb-16 packages-title">
                  {result.upscale_your_team_and_plans_heading}
                </h2>
              </div>
            </div>

            <div className="lg:grid gap-5 grid-cols-3 col-lg-10 mx-auto px-0">
              {result.upscale_grid.map((upscale, index) => (
                <div
                  key={index}
                  className="mb-4 lg:mb-0 col-item flex flex-col text-center justify-start items-center w-full relative"
                >
                  <div className="service-icon mb-4">
                    <img
                      src={upscale.upscale_icon.url}
                      alt={upscale.upscale_icon.alt}
                    />
                  </div>
                  <h3 className="service-title mb-3 item-heading font-semibold text-2xl">
                    {upscale.upscale_heading}
                  </h3>
                  <p className="description mb-0">
                    {RichTextRender(upscale.upscale_copy)}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section id="support_packages">
          <div className="container block py-16">
            <div className="row flex justify-center">
              <div className="col-12 col-lg-10 text-center">
                <h2 className="mt-0 mb-2 packages-title text-3.5xl font-semibold">
                  {result.support_packages_heading}
                </h2>
                <p className="block packages-sub mb-12">
                  Get the right level of support for your team to focus on true
                  innovation.
                </p>
              </div>
            </div>

            <div className="md:grid gap-5 md:grid-cols-2 xl:grid-cols-4 col-lg-10 mx-auto px-0">
              {result.packages_grid.map((packitem, index) => (
                <div
                  key={index}
                  id={packitem.package_heading.toLowerCase()}
                  className="border-b-0 package-item mb-4 lg:mb-0  w-full relative"
                >
                  <h3
                    id={packitem.package_heading.toLowerCase()}
                    className="text-center font-semibold package-title category mb-0 w-full"
                  >
                    {packitem.package_heading}
                  </h3>
                  <div
                    className="category"
                    id={packitem.package_heading.toLowerCase()}
                  >
                    <label
                      className={`chat icon block w-full text-center mt-0`}
                    >
                      Chat <span></span>
                    </label>
                    <label className={`phone icon block w-full text-center`}>
                      24/7 Emergency Phone<span></span>
                    </label>
                    <label className={`email icon block w-full text-center`}>
                      Email / Online Tickets<span></span>
                    </label>
                  </div>

                  <div className="category">
                    <label className="label-guaranteed font-semibold block w-full text-center mt-0">
                      {packitem.grt_heading}
                    </label>
                    <div className="flex pb-3 items-center justify-center">
                      <span className="text-center">Critical:</span>
                      <span className="subtext pl-1 text-center font-semibold">
                        {packitem.critical_urgency}
                      </span>
                    </div>
                    <div className="flex pb-3 items-center justify-center">
                      <span className="text-center">High:</span>
                      <span className="subtext pl-1 text-center font-semibold">
                        {packitem.high_urgency}
                      </span>
                    </div>
                    <div className="flex pb-3 items-center justify-center">
                      <span className="text-center">Low:</span>
                      <span className="subtext pl-1 text-center font-semibold">
                        {packitem.low_urgency}
                      </span>
                    </div>
                  </div>
                  <div className="price flex flex-col items-center">
                    <label className="text-center font-semibold mt-0">
                      Price
                    </label>
                    <div className="price-tag inline-block mt-3 text-center">
                      {RichTextRender(packitem.package_price)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="support_packages_footer_text text-center">
              {RichTextRender(result.support_packages_footer_text)}
            </div>
          </div>
        </section>

        <section id="aws-banner">
          <div className="container">
            <div className="text-center col-lg-10 mx-auto container block pt-20 pb-20">
              <img
                className="aws-image mx-auto max-w-screen-sm"
                src={result.aws_image.url}
                alt={result.aws_image.alt}
              />
              <h2 className="mt-8 mb-2 block packages-title aws-title">
                {result.aws_heading}
              </h2>
              <div className="aws-subtitle col-lg-10 mx-auto container block px-4 xl:px-16">
                {result.aws_sub_heading}
              </div>

              <div className="feature-items flex flex-wrap mt-8 mb-8">
                {result.feature_grid.map((featItem, index) => (
                  <div
                    key={index}
                    className="pb-8 px-8 xl:px-14 lg:pb-0 w-full lg:w-1/3 feature-item package-item text-center font-regular package-title category mb-0"
                  >
                    <div className="mb-8 mx-auto icon tick block"></div>
                    {featItem.feature_copy}
                  </div>
                ))}
              </div>

              <a
                className="inline-block btn-boldish amazeeio-button rounded-full px-4 py-2 inverted cursor-pointer"
                target="_blank"
                href={result.aws_cta_link.url}
              >
                Go to AWS Marketplace
              </a>
            </div>
          </div>
        </section>

        <section id="faqs">
          <div className="container block py-16">
            <div className="row flex justify-center">
              <div className="col-12 col-lg-10 text-center">
                <h2 className="block mt-0 mb-16 packages-title">
                  {result.faqs_section_heading}
                </h2>
              </div>
            </div>

            <div className="row col-lg-10 mx-auto px-0">
              {result.faq_items.map((faq, index) => (
                <div className="col-12 faq relative" key={index}>
                  <label className="mb-0 w-full" htmlFor={`faq-${index}`}>
                    {faq.faq_heading}
                  </label>
                  <input
                    id={`faq-${index}`}
                    className="fa fa-chevron-down toggle appearance-none cursor-pointer flex justify-end items-start absolute top-0 right-0"
                    type="checkbox"
                  ></input>
                  <div className="description">
                    {RichTextRender(faq.faq_copy)}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section id="footer-cta">
          <div className="container">
            <div className="row">
              <div className="col-12 d-flex justify-center flex-col items-center">
                <div className="footer-cta-title">
                  <p className="col-lg-8 container block text-center mb-0">
                    {result.cta_title}
                  </p>
                </div>
                <div className="aws-cta mt-4">
                  <a
                    className="inline-block btn-boldish amazeeio-button rounded-full px-4 py-2 inverted cursor-pointer"
                    href="https://signup.amazee.io"
                  >
                    Get Started
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </AmazeePage>
  );
};

const StaticPricingQueryPage: React.FC<{ location: Location }> = ({
  location,
}) => {
  return (
    <StaticQuery
      query={query}
      render={(data) => <PricingQueryPage location={location} data={data} />}
    />
  );
};
export default StaticPricingQueryPage;
