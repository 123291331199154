import {
  PrismicExternalLink,
  PrismicImage,
  PrismicRichText,
  PrismicPageDefinition,
} from "./types";

export type TypeCloud_packages = {
  cloud_package_heading: string;
  pricing_starting_at: string;
  pricing_additional_projects: string;
  guaranteed_uptime: string;
  cloud_package_image: PrismicImage;
  cloud_subheading: string;
};

export type TypeCloud_available_add_ons = {
  cloud_add_on_heading: string;
  add_ons_copy: PrismicRichText;
  cloud_contact_sales: PrismicExternalLink;
};

export type TypeFaq_items = {
  faq_copy: PrismicRichText;
  faq_heading: string;
};

export type TypePackages_grid = {
  package_heading: string;
  grt_heading: string;
  critical_urgency: string;
  high_urgency: string;
  low_urgency: string;
  package_price: PrismicRichText;
};

export type TypeUpscale_grid = {
  upscale_icon: PrismicImage;
  upscale_heading: string;
  upscale_copy: string;
};

export type TypeAWSfeature_grid = {
  feature_copy: string;
};

export type TypePricingPage = {
  title: string;
  meta_title: string;
  subtitle: PrismicRichText;
  hero_image: PrismicImage;
  page_meta_description: string;
  page_meta_thumbnail: PrismicImage;

  cloud_section_heading: string;
  cloud_packages: TypeCloud_packages[];
  cloud_package_extra_text: PrismicRichText;

  cloud_included: PrismicRichText;
  cloud_basic_included: PrismicRichText;
  cloud_available_add_ons: TypeCloud_available_add_ons[];

  dedicated_cloud_section_heading: string;
  ded_custom_pricing_text: PrismicRichText;
  dedicated_cloud_features: PrismicRichText;
  dedicated_cloud_guaranteed_uptime: PrismicRichText;
  dedicated_cloud_included: PrismicRichText;
  dedicated_cloud_add_ons: PrismicRichText;
  dedicated_cloud_contact_sales: PrismicExternalLink;
  ded_cloud_package_image: PrismicImage;
  ded_cloud_subheading: string;
  ded_cloud_package_extra_text: PrismicRichText;

  faqs_section_heading: string;
  faq_items: TypeFaq_items[];

  support_packages_heading: string;
  packages_grid: TypePackages_grid[];
  support_packages_footer_text: PrismicRichText;

  upscale_your_team_and_plans_heading: string;
  upscale_grid: TypeUpscale_grid[];

  aws_image: PrismicImage;
  aws_heading: string;
  aws_sub_heading: string;
  feature_grid: TypeAWSfeature_grid[];
  aws_cta_link: PrismicExternalLink;

  cta_title: string;
  cta_link: PrismicExternalLink;
};

export type TypePricingQueryResult = {
  allPrismicPricing: {
    edges: Array<{
      node: { data: TypePricingPage };
    }>;
  };
};

export const getPage = (data: TypePricingQueryResult): TypePricingPage => {
  return data.allPrismicPricing.edges[0].node.data;
};
